import Rails from "@rails/ujs";
import { Controller } from "stimulus";
import schema from "../packs/validation_schema";

export default class extends Controller {
  static targets = ["form", "editBtn"];

  validate(event) {
    let form_valid = false;
    const form = $(this.formTarget);
    if (form_valid) {
      form_valid = false; // reset flag
      return; // let the event bubble away
    }
    event.preventDefault();
    const targetForm = event.currentTarget.getAttribute("data-target-form");
    const rules = schema[targetForm];
    form.validate({ debug: true, ...rules });
    if (form.valid()) {
      form_valid = true;
      if (form.data("remote")) {
        Rails.fire(form[0], "submit");
      } else {
        this.formTarget.submit();
      }
    } else {
      form_valid = false;
    }
  }
  product_publish(event){
    $("#product-status").val("approved");
    this.validate(event);
  }
   product_draft(event){
    $("#product-status").val("draft");
    this.validate(event);
  }
}
