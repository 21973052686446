$(document).on("click", '[data-toggle-play="we-excel-video"]', function () {
  const player = $("#we-excel-video")[0];
  if (player.paused) {
    $(this).children().toggleClass("fa-play fa-pause");
    player.play();
  } else {
    $(this).children().toggleClass("fa-play fa-pause");
    player.pause();
  }
});

//admin category management
$(function () {
  $("#catg-collapse1")
    .on("show.bs.collapse", function () {
      $(".btn-collapse1").addClass("vis");
    })
    .on("hide.bs.collapse", function () {
      $(".btn-collapse1").removeClass("vis");
    });
  $("#catg-collapse2")
    .on("show.bs.collapse", function () {
      $(".btn-collapse2").addClass("vis");
    })
    .on("hide.bs.collapse", function () {
      $(".btn-collapse2").removeClass("vis");
    });
  $("#catg-collapse3")
    .on("show.bs.collapse", function () {
      $(".btn-collapse3").addClass("vis");
    })
    .on("hide.bs.collapse", function () {
      $(".btn-collapse3").removeClass("vis");
    });
  $("#catg-collapse4")
    .on("show.bs.collapse", function () {
      $(".btn-collapse4").addClass("vis");
    })
    .on("hide.bs.collapse", function () {
      $(".btn-collapse4").removeClass("vis");
    });
});

//hide collapse menu on outside click
$(function () {
  $(document).click(function (event) {
    $("#usr-nav").collapse("hide");
    $("#cat-nav").collapse("hide");
    $("#noti-nav").collapse("hide");
    $("#collapse-id-1").collapse("hide");
    $("#collapse-id-2").collapse("hide");
    $("#collapse-id-3").collapse("hide");
  });
});

// popup for star ratings
$(function () {
$(".rating-order-btn").click(function(event) {
  $(".rating-order-popup").css("display","flex");
});
});