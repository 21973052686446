export function handleErrorPlacement(error, element) {
  if (
    element.attr("type") == "radio" ||
    element.attr("type") == "checkbox" ||
    element.attr("type") == "file" 
    ) {
    var error_div = element.parent().parent().find(".error-holder");
  $(error_div).html(error);
} else {
  element.parent().append(error);
}
}

    jQuery.validator.addMethod('selectcheck', function (value,element) {
        const sub_options_length = $('.get_subs>option').length;
        const option_selected = $(".get_subs>option:selected").val();
        if (sub_options_length==1  && option_selected=="") {
          return true
        }
        else if(sub_options_length>1 && option_selected != ""){
          return true
        }
        else if(sub_options_length>1 && option_selected == ""){
          return false
        }
        

        else{
          return false
        }
    },"This field is required");


    jQuery.validator.addMethod('selectsub_category', function (value,element) {
        const options_length= $(".getsubs>option").length;
        const selected_value = $(".getsubs>option:selected").val();

        if (options_length==1 && selected_value=="") {
          return  true
        }
        else if(options_length>1 && selected_value != ""){
          return  true
        }
        else if(options_length>1 &&  selected_value == ""){
          return false
        }

        else{
          return false
        }
    },"This field is required");


const schema = {
  newcustomer:{
    rules:{
    "customer[full_name]": {
        required: true,
      },
      "customer[designation]": {
        required: true,
      },                                                                                  
      "customer[email]": {
        required: true,
        email: true
      },
      "customer[phone]": {
       required: true,
       number: true,
       minlength:10,
       maxlength:10
      },
      "customer[country_code]": {
       required: true,
       number: true,
       minlength:1,
       maxlength:6
     },
     "customer[business_attributes][name]": {
      required: true,
    },
    "customer[business_attributes][phone_number]": {
      required: true,
      number: true,
      minlength:10,
      maxlength:10

    },
    "customer[business_attributes][whatsapp_number]": {
      number: true,
      minlength:10,
      maxlength:10
    },
    "customer[business_attributes][industry_id]":{
      required: true,

    },
    "customer[business_attributes][business_type]": {
      required: true,

    },
    "customer[business_attributes][preferred_currency]": {
      required: true,
    },
    "customer[logo]": {
      required: true, 
    },
    "customer[business_attributes][addresses_attributes][0][street]": {
      required: true,
    },
    "customer[business_attributes][addresses_attributes][0][full_address]": {
      required: true,
    },
    "customer[business_attributes][addresses_attributes][0][country]": {
      required: true,
    },
    "customer[business_attributes][addresses_attributes][0][state]": {
      required: true,
    },
    "customer[business_attributes][addresses_attributes][0][city]": {
      required: true,
    },
    "customer[business_attributes][addresses_attributes][0][zip_code]": {
      required: true,
    },

    "customer[business_attributes][addresses_attributes][1][street]": {
      required: true,
    },
    "customer[business_attributes][addresses_attributes][1][full_address]": {
      required: true,
    },
    "customer[business_attributes][addresses_attributes][1][country]": {
      required: true,
    },
    "customer[business_attributes][addresses_attributes][1][state]": {
      required: true,
    },
    "customer[business_attributes][addresses_attributes][1][city]": {
      required: true,
    },
    "customer[business_attributes][addresses_attributes][1][zip_code]": {
      required: true,
    },
  },                                              
  messages: {
    "customer[full_name]": "Enter full name",



  },              
  errorPlacement: function (error, element) {
    handleErrorPlacement(error, element);
  },
}, 

login: {
  rules: {
    "user[email]": {
      required: true,
      email: true,
    },
    "user[password]": {
      required: true,
    },
    "user[remember_me]": {
      required: false,
    },
  },
  messages: {
    "user[email]": "Enter valid email address",
    "user[password]": "Enter password",
  },
  errorPlacement: function (error, element) {
    handleErrorPlacement(error, element);
  },
},
signup: {
  rules: {
    "user[full_name]": {
      required: true,
    },
    "user[designation]": {
      required: true,
    },
    "user[email]": {
      required: true,
      email: true,
    },
    "user[country_code]": {
      required: true,
    },
    "user[phone]": {
      required: true,
      number:true,
      minlength:10,
      maxlength:13
    },
    "user[country_code]": {
      required: true,
    },
    "user[password]": {
      required: true,
      minlength: 8
    },
    "user[password_confirmation]": {
      required: true,
      equalTo: "#user_password",
    },
    agree_terms_and_conditions: {
      required: true,
    },
  },
  messages: {
    "user[full_name]": "Enter Full name",
    "user[designation]": "Enter designation",
    "user[email]": "Enter valid email address",
    "user[country_code]": "Select country code",
    "user[phone]": "Enter valid phone number",
    "user[password_confirmation]": {
      required: "Enter password confirmation",
      equalTo: "Confirm password does not match to password",
    },
  },
  errorPlacement: function (error, element) {
    handleErrorPlacement(error, element);
  },
},

otp: {
  rules: {  
    "user[otp]": {
      required: true,
      number: true,
      minlength: 6,
      maxlength: 6,
    },
  },
  messages: {
    "user[otp]": {
      required: "Enter OTP",
      number: "Please enter valid OTP",
      maxlength: "Please enter valid OTP",
      minlength: "Please enter valid OTP",
    },
  },
  errorPlacement: function (error, element) {
    handleErrorPlacement(error, element);
  },
},
openbid:{
  rules:{
    "bid[category_id]":{
      required: true
    },
    "bid[name]":{
      required:true
    },
    
    "bid[product_image]":{
      required:true
    },
    "bid[description]":{
      required:true
    },
    "bid[specifications]":{
      required:true
    },
    "bid[quantity]":{
      required:true
    },
    "bid[attribute_1]":{
      required:true
    },
    "bid[attribute_2]":{
      required:true
    },
    "bid[term_of_payment]":{
      required:true
    },
    
    "bid[shipping_mode]":{
      required:true
    },
    "bid[drawings][]":{
      required:true
    },
    "bid[purchase_order]":{
      required:true
    },
  },
},
custombid:{
  rules:{
   "bid[category_id]":{
      required: true
    },
  "bid[name]":{
    required:true
  },
  "bid[description]":{
    required:true
  },
  "bid[product_image]":{
      required:true
    },
  "bid[bid_type]":{
    required:true
    },
  "bid[delivery_timelines_attributes][0][quantity]":{
    required:true,
  },
  "bid[term_of_payment]":{
    required:true
  },
  "bid[delivery_timelines_attributes][0][expected_date]":{
    required:true
  },
  "bid[full_address]":{
    required: true
  },
  "bid[zip_code]":{
    required:true
  },
  "bid[closer_date]":{
    required:true
  },
  "bid[drawings][]":{
      required:true
    },
},
errorPlacement: function(error, element) {
    if (element.hasClass('select-2')) {
        error.appendTo(element.next('.select2'))

    } else {
        error.insertAfter(element);
    }
}
},
createorder:{
  rules:{
    "order[title]":{
      required:true
    },
    "order[total_price]":{
      required: true
    },
    "order[term_of_payment]":{
      required:true
    },
    "order[quantity]":{
      required:true
    },
    "order[description]":{
      required:true
    },
    "order[drawings][]":{
      required:true
    },
    "order[purchase_order]":{
      required:true
    }
  }
},
customrfq:{
  rules:{
    "rfq[customer][full_name]":{
      required:true
    },
    "rfq[customer][country_code]":{
      required:true
    },
    "rfq[customer][phone]":{
      required:true
    },
    "rfq[kind]":{
      required:true
    },
    "rfq[name]":{
      required:true
    },
    "rfq[description]":{
      required:true
    },
    // "rfq[material_specification]":{
    //   required:true
    // },
    // "rfq[manufacturing_standard]":{
    //   required:true
    // },
    "rfq[delivery_schedule]":{
      required:true
    },
    "rfq[quantity]":{
      required:true
    },
    "rfq[product_unit]":{
      required:true
    },
    "rfq[currency]":{
      required:true
    },
    "rfq[term_of_delivery]":{
      required:true
    },
    "rfq[expected_date]":{
      required:true
    },
    "rfq[address_attributes][zip_code]":{
      required:true
    }
  }
},
 addproduct:{
  rules:{
    "product[name]":{
      required: true
    },
    "product[description]":{
      required: true
    },
    "product[parent_id]":{
      required:true
    },
    "product[category_id]":{
      selectsub_category: true
    },
    "product[sub_category_id]":{
      selectcheck: true
    },
    "product[images][]":{
      required:true
    },
    "product[product_properties_attributes][0][name]":{
      required:true
    },
    "product[product_properties_attributes][0][value]":{
      required: true
    },
    "product[faqs_attributes][0][question]":{
      required: true
    },
    "product[faqs_attributes][0][answer]":{
      required:true
    },
    "product[kind]":{
      required: true
    },
    "product[more_information]":{
      required: true
    }
    }
  },
  supplier_joiningformalities:{
    rules:{
      "business[name]":{
        required:true
      },
      "business[country_code]":{
        required:true
      },
      "business[phone_number]":{
        required:true,
        number:true,
        minlength:10,
        maxlength:13
      },
      // "business[whatsapp_country_code]":{
      //   required:true
      // },
      // "business[whatsapp_number]":{
      //   required:true,
      //   number:true,
      //   minlength:10,
      //   maxlength:13
      // },
      "business[logo]":{
        required:true
      },
      "business[industry_id]":{
        required:true
      },
      "business[business_type]":{
        required:true
      },
      "business[pan_card_no]":{
        required:true
      },
      "business[pan_card]":{
        required:true
      },
      "business[cancelled_cheque]":{
        required:true
      },
      "business[gst_certificate]":{
        required:true
      },
      "business[business_profile]":{
        required:true
      },
      "business[addresses_attributes][0][full_address]":{
        required:true
      },
      "business[addresses_attributes][0][zip_code]":{
        required:true
      },
      "business[addresses_attributes][0][country]":{
        required:true
      },
      "business[addresses_attributes][0][state]":{
        required:true
      },
      "business[addresses_attributes][0][city]":{
        required:true
      },
      "business[addresses_attributes][1][full_address]":{
        required:true
      },
      "business[addresses_attributes][1][zip_code]":{
        required:true
      },
      "business[addresses_attributes][1][country]":{
        required:true
      },
      "business[addresses_attributes][1][state]":{
        required:true
      },
      "business[addresses_attributes][1][city]":{
        required:true
      }
    },
errorPlacement: function(error, element) {
    if (element.hasClass('select-2')) {
        error.appendTo(element.next('.select2'))

    } else {
        error.insertAfter(element);
    }
}


},
company_reg_address:{
  rules:{
    "address[full_address]":{
      required:true
    },
    "address[zip_code]":{
      required:true
    },
    "address[country]":{
      required:true
    },
    "address[state]":{
      required:true
    }
  },
  errorPlacement: function(error, element) {
    if (element.hasClass('select-2')) {
        error.appendTo(element.next('.select2'))

    } else {
        error.insertAfter(element);
    }
}

},

  customer_joiningformalities:{
    rules:{
      "business[name]":{
        required:true
      },
      "business[country_code]":{
        required:true
      },
      "business[phone_number]":{
        required:true,
        minlength:10,
        maxlength:13,
        number:true
      },
      // "business[whatsapp_country_code]":{
      //   required:true,
      // },
      // "business[whatsapp_number]":{
      //   required:true,
      //   minlength:10,
      //   maxlength:13,
      //   number:true
      // },
      "business[preferred_currency]":{
        required:true
      },
       "business[business_type]":{
        required:true
      },
      "business[addresses_attributes][0][full_address]":{
        required:true
      },
      "business[addresses_attributes][0][country]":{
        required:true
      },
      "business[addresses_attributes][0][state]":{
        required:true
      },
      "business[addresses_attributes][0][city]":{
        required:true
      },
      "business[addresses_attributes][0][zip_code]":{
        required:true
      },
      "business[addresses_attributes][1][full_address]":{
        required:true
      },
      "business[addresses_attributes][1][zip_code]":{
        required:true
      },
      "business[addresses_attributes][1][country]":{
        required:true
      },
      "business[addresses_attributes][1][state]":{
        required:true
      },
      "business[addresses_attributes][1][city]":{
        required:true
      }
    },
    errorPlacement: function(error, element) {
    if (element.hasClass('select-2')) {
        error.appendTo(element.next('.select2'))

    } else {
        error.insertAfter(element);
    }
}
  },
  admin_rfqedit:{
    rules:{
      "rfq[name]":{
        required:true
      },
      // "rfq[manufacturing_standard]":{
      //   required:true
      // },
      "rfq[description]":{
        required:true
      },
      // "rfq[material_specification]":{
      //   required:true
      // },
      "rfq[delivery_schedule]":{
        required:true
      },
      "rfq[quantity]":{
        required:true,
        number:true
      },
      "rfq[currency]":{
        required:true
      },
      "rfq[product_unit]":{
        required: true
      },
      "rfq[expected_date]":{
        required:true
      },
      "rfq[phone_number]":{
        required:true
      },
      "rfq[email]":{
        required:true,
         email: true
      },
      "rfq[address_id]":{
        required:true
      }
    }
  },
  rfq_upload_quotation:{
    rules:{
      "rfq[line_items_attributes][0][total_price]":{
        required:true,
        number:true
      },
      "rfq[line_items_attributes][0][quantity]":{
        required:true,
        number:true
      },
      "rfq[quotation]":{
        required:true
      }
    },
    errorPlacement:function(error,element){
      var name = $(element).attr("name");
      if (name=="rfq[quotation]"){
        error.insertAfter($(element).parent().parent());
      }else{
        error.insertAfter($(element));
      }
    }
  },
  customer_po_upload:{
    rules:{
      "rfq[purchase_order_number]":{
        required:true
      },
      "rfq[po_value]":{
        required:true,
        number:true
      },
      "rfq[purchase_order]":{
        required:true
      }
    },
    errorPlacement:function(error,element){
      var name = $(element).attr("name");
      if (name=="rfq[purchase_order]"){
        error.insertAfter($(element).parent().parent());
      }else{
        error.insertAfter($(element));
      }
    }
  },
  admin_po_upload:{
    rules:{
      "rfq[purchase_order_number]":{
        required:true
      },
      "rfq[po_value]":{
        required:true,
        number:true
      },
      "rfq[purchase_order]":{
        required:true
      }
    },
    errorPlacement:function(error,element){
      var name = $(element).attr("name");
      if (name=="rfq[purchase_order]"){
        error.insertAfter($(element).parent().parent());
      }else{
        error.insertAfter($(element));
      }
    }
  },
  upload_order_invoice:{
    rules:{
      "invoice[invoice_num]":{
        required:true
      },
      "invoice[due_date]":{
        required:true
      },
      "invoice[price]":{
        required:true,
        number:true
      },
      "invoice[invoice_attachment]":{
        required:true
      }
    },
    messages:{
      "invoice[invoice_num]": "Enter invoice number",
      "invoice[due_date]": "Select due date",
      "invoice[price]": "Enter price",
      "invoice[invoice_attachment]": "Upload invoice file"
    }
    ,
    errorPlacement:function(error,element){
      if(element.attr("name") == "invoice[invoice_attachment]"){
        error.appendTo($("#invoice_attachment_error"));
      }else{
        error.insertAfter(element);
      }
    }
  },
  new_shipment_details:{
    rules:{
      "shipping[awb_number]":{
        required:true
      },
      "shipping[carrier_id]":{
        required:true
      },
      "items[0][quantity]":{
        required:true
      }
    }
  },
rfq:{
  rules:{
   "guest_rfq[line_items][0][total_quantity]":{
    required: true
   },
   "guest_rfq[line_items][0][delivery_timelines][0][quantity]":{
    required:true
   },
   "guest_rfq[line_items][0][delivery_timelines][0][expected_date]":{
    required:true
   },
   "guest_rfq[line_items][0][shipping_address][full_address]":{
    required: true
   },
   "guest_rfq[line_items][0][shipping_address][street]":{
    required: true
   },
   "guest_rfq[line_items][0][shipping_address][phone]":{
    required: true
   },
   "guest_rfq[line_items][0][shipping_address][zip_code]":{
    required: true
   },
   "guest_rfq[line_items][0][shipping_address][country]":{
    required: true
   },
   "guest_rfq[line_items][0][shipping_address][state]":{
    required: true
   },
   "guest_rfq[line_items][0][shipping_address][city]":{
    required:true
   },
   "guest_rfq[special_instructions]":{
    required: true
   },
   "guest_rfq[shipping_mode]":{
    required: true
   },
   "guest_rfq[billing_address][full_address]":{
    required: true
   },
   "guest_rfq[billing_address][street]":{
    required: true
   },
   "guest_rfq[billing_address][phone]":{
    required: true
   },
   "guest_rfq[billing_address][zip_code]":{
    required:true
   },
   "guest_rfq[billing_address][country]":{
    required:true
   },
   "guest_rfq[billing_address][state]":{
    required:true
   },
   "guest_rfq[billing_address][city]":{
    required:true
   }  
}
},
password_reset:{
  rules:{
    "user[password]":{
      required:true
    },
    "user[password_confirmation]":{
      required:true,
      equalTo: "#user_password",
    }
  },
  messages:{
    "user[password]": "Enter Password",
    "user[password_confirmation]":{
      required: "Enter password confirmation",
      equalTo: "Confirm password does not match to password"
    }
  }
},
category_upload:{
  rules:{
    "csv_upload[csv]":{
      required:true
    },
    "csv_upload[zip_file]":{
      required:true
    }
  },
  messages:{
    "csv_upload[csv]":"Upload File",
    "csv_upload[zip_file]": "Upload File"
  },
  errorPlacement:function(error,element){
    var label = $(element).parent().parent().parent();
    error.insertAfter(label);
  }
},
catalog_upload:{
  rules:{
    "csv_upload[csv]":{
      required:true
    },
    "csv_upload[zip_file]":{
      required:true
    }
  },
  messages:{
    "csv_upload[csv]":"Upload File",
    "csv_upload[zip_file]": "Upload File"
  },
  errorPlacement:function(error,element){
    var label = $(element).parent().parent().parent();
    error.insertAfter(label);
  }
},
supplier_custom_bid_submit:{
  rules:{
    "bid_submission[delivery_timelines_attributes[0][quantity]]":{
      required: true,
      number: true,
      min: 0
    },
    "bid_submission[amount]":{
      required:true,
      number: true,
      min: 0
    },
    "bid_submission[bid_quotation]":{
      required:true
    },
    "bid_submission[moq]":{
      number:true,
      min:0
    },"bid_submission[term_of_payment]":{
      number:true,
      min:0
    },"bid_submission[advance]":{
      number:true,
      min:0
    }
  },
  messages:{
    "bid_submission[delivery_timelines_attributes[0][quantity]]":{
      number: "Enter a valid number",
      required: "Enter quantity",
      min: "Enter a number greater than 0"
    },
    "bid_submission[amount]": {
      required : "Enter bid amount",
      number: "Enter valid number",
      min: "Enter a number greater than 0"
    },
    "bid_submission[bid_quotation]": "Upload bid quotation file"
  },
  errorPlacement:function(error,element){
    if(element.attr("name")=="bid_submission[amount]"){
      error.appendTo("#bid_submission_amount_error");
    }else if(element.attr("name")=="bid_submission[delivery_timelines_attributes[0][quantity]]"){
      error.appendTo("#quantity_error");
    }
    else{
      var name = $(element).attr("id");
      error.appendTo($("#" + name + "_error"));
    }
  }
},
edit_custom_submit_bid:{
  rules:{
    "bid_submission[amount]":{
      required:true
    }
  },
  messages:{
    "bid_submission[amount]":"Enter bid amount"
  }
},
payment:{
  rules:{
    "payment[price]":{
      required:  true
    },
    "payment[payment_receipt]":{
      required: true
    },
    "payment[comments]":{
      required: true
    }
  },
  messages:{
    "payment[price]": "Enter the price",
    "payment[payment_receipt]": "Upload Payment Receipt File",
    "payment[comments]": "Enter your comments"
  },
  errorPlacement:function(error,element){
    var invoice_id =$(element).parent().parent().find("input#payment_invoice_id").val();
    var name = $(element).attr("id");
    if (name=="payment_payment_receipt"){
      error.insertAfter($(element).parent().parent());
    }else{
      error.insertAfter($(element));
    }
  }
},
supplier_quotation_upload:{
  rules:{
    "bid_submission[amount]":{
      required:true,
      number:true,
      min:0
    }
  },messages:{
    "bid_submission[amount]":{
      min: "Please enter a positive number"
    }
  }
},
assign_manager_to_customer:{
  rules:{
    "customer[account_manager_name]":{
      required:true
    },
    "customer[account_manager_email]": {
      required: true,
      email: true
    },
    "customer[account_manager_phone]": {
       required: true,
       number: true,
       minlength:10,
       maxlength:10
      },
  },messages:{
    "customer[account_manager_name]": "Enter full name."
  }
},supplier_po_upload:{
  rules:{
    "supplier_order[purchase_order_number]":{
      required: true
    },
    "supplier_order[po_value]":{
      required: true,
      number: true,
      min: 0
    },
    "supplier_order[purchase_order]":{
      required: true
    }
  },messages:{
    "supplier_order[po_value]":{
      min: "Please enter a positive number"
    }
  },
  errorPlacement:function(error,element){
    var invoice_id =$(element).parent().parent().find("input#payment_invoice_id").val();
    var name = $(element).attr("name");
    if (name=="supplier_order[purchase_order]"){
      error.insertAfter($(element).parent().parent());
    }else{
      error.insertAfter($(element));
    }
  }
}
};

export default schema;