import { Controller } from "stimulus";
import Rails from "@rails/ujs";
export default class extends Controller {
  static values = { orderid: String, uniqid: String, path: String }
  static targets = [ "results"]
  
  connect(){
    // // alert(this.orderidValue)
    // fetch(this.orderidValue, this.uniqidValue).then((val) => {
    //     console.log(this.orderidValue)
    //   }
    // )
    var order_id = this.orderidValue,
      uniq_id = this.uniqidValue,
      path = this.pathValue;
    // alert(uniq_id)
    var that = this;
    Rails.ajax({
      type: "get",
      url: path + order_id + "/get_shipping_history?uniq_id=" + uniq_id,
      success: function (response) {
        console.log(response)
        that.resultsTarget.innerHTML = response.result;
      },
      error: function (response) {
        Toastify({
          text: "Something went wrong",
          duration: 5000,
          close: true,
          gravity: "top",
          position: "right",
          className: "toastify-danger",
          stopOnFocus: true,
        }).showToast();
      }
    });
  }

  toggle_shipping_details(){
    $(this.resultsTarget).find(".tracking-details").toggle();
  }

  toggle_tracking_history(){
    $(this.resultsTarget).find(".travel-history").toggle();
  }

}